a<template>
  <div class="right-bar">
    <div class="right-bar__back" @click="changeToggleChangePassword(false)"/>
    <div class="right-bar__content">
      <div class="right-bar__close" @click="changeToggleChangePassword(false)"></div>`
      <div class="right-bar__title col-md-10">
        Change Your Password
      </div>
      <div class="right-bar__form col-md-6">
        <div class="right-bar__form-block row">
          <label class="input-block col-md-12">
            <input type="password" placeholder="Password"><span/>
          </label>
          <label class="input-block col-md-12">
            <input type="password" placeholder="Repeat password"><span/>
          </label>
        </div>
        <div class="right-bar__form-block row">
          <div class="col-md-12 d-flex align-center">
            <button class="btn grey mr-3">Changed</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex";

export default {

  methods:{
    ...mapMutations({
      changeToggleChangePassword: 'general/changeToggleChangePassword',
    })
  }
}
</script>
